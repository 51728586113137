import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './CustomSlider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import imagen1 from '../assets/planes.jpg';
import imagen2 from '../assets/entramiento y nutrición.jpg';  

const CustomSlider = () => {
  const sliderRef = useRef(null);

  const slides = [
    {
      image: imagen1,
      titulo: 'NUESTROS PLANES',
      description: '¿Quieres progresar de forma adecuada con una buena alimentación y un entrenamiento que te permita progresar en tus objetivos? Aquí tienes nuestros planes de entrenamiento y nutrición personalizados, ponemos a tu disposición todo lo que necesitas para que tu cambio se haga realidad.',
      link: '/planes',
      ir: 'Ver todos los Planes'
    },
    {
      image: imagen2,
      titulo: '¿QUIÉNES SOMOS?',
      description: 'Descubre quiénes están detrás de ShapeU! Juntos podremos conseguir tus metas.',
      link: '/conocenos',
      ir: 'Conócenos'
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false, // Disable default arrows
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide.image} alt={`Slide ${index + 1}`} />
            <div className="slide-content">
              <div className="slide-description">
                <h2>{slide.titulo}</h2>
                <p>{slide.description}</p>
                <Link to={slide.link}>{slide.ir}</Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <button className="prev-button" onClick={previous}>
        <ArrowBackIosIcon />
      </button>
      <button className="next-button" onClick={next}>
        <ArrowForwardIosIcon />
      </button>
    </div>
  );
};

export default CustomSlider;
