import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import emailjs from '@emailjs/browser';
import './Planes.css';

const Planes = () => {
    const [activeCard, setActiveCard] = useState(null);
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        codigoPostal: '',
        telefono: '',
        plan:''
    });
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const location = useLocation();
    const auth = getAuth();
    const user = auth.currentUser;
    const form = useRef(); // Referencia al formulario

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const cardIndex = queryParams.get('card');
        if (cardIndex !== null) {
            setActiveCard(Number(cardIndex));
        }
    }, [location.search]);

    useEffect(() => {
        if (user) {
            setFormData({
                nombre: user.displayName?.split(' ')[0] || '',
                apellido: user.displayName?.split(' ').slice(1).join(' ') || '',
                correo: user.email || '',
                codigoPostal: '',
                telefono: '',
                plan: ''
            });
        }
    }, [user]);

    const handleToggle = (index) => {
        if (activeCard === index) {
            // Si la misma tarjeta se desactiva, limpiamos el plan
            setActiveCard(null);
            setFormData({
                ...formData,
                plan: ''
            });
        } else {
            // Si se selecciona una nueva tarjeta, actualizamos el plan
            setActiveCard(index);
            setFormData({
                ...formData,
                plan: cardsData[index].title
            });
        }
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const errors = [];
        if (!formData.nombre.trim()) errors.push('El nombre es requerido.');
        if (!formData.apellido.trim()) errors.push('El apellido es requerido.');
        if (!formData.correo.trim() || !/\S+@\S+\.\S+/.test(formData.correo)) errors.push('El correo electrónico es inválido.');
        if (!formData.codigoPostal.trim() || !/^\d{5}$/.test(formData.codigoPostal)) errors.push('El código postal debe ser un número de 5 dígitos.');
        if (!formData.telefono.trim() || !/^\d{9}$/.test(formData.telefono)) errors.push('El teléfono debe ser un número de 9 dígitos.');

        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();

        if (errors.length > 0) {
            setMessage(errors.map(error => `<p>${error}</p>`).join(''));
            setOpen(true);
            return;
        }

        // Envío del correo al administrador
        emailjs
            .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
            .then(() => {
                setMessage(`<p>Formulario enviado con éxito.</p><p>ShapeU! contactará contigo.</p><p>Muchas gracias!</p>`);
                setOpen(true);
                setFormData({
                    nombre: '',
                    apellido: '',
                    correo: '',
                    codigoPostal: '',
                    telefono: '',
                    plan: ''
                });
            }, (error) => {
                setMessage(`<p>Error al enviar el correo. Inténtalo de nuevo.</p>`);
                setOpen(true);
                console.error('Error al enviar el correo:', error);
            });

        // Envío del correo de confirmación al usuario
        emailjs
            .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_USER, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
            .then(() => {
                setOpen(true);
            }, (error) => {
                console.error('Error al enviar el correo de confirmación al usuario:', error);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cardsData = [
        {
            title: 'Plan Base', description: (
                <>
                    <p>Cuestionario inicial para estudiar tu caso y ponernos manos a la obra.</p>
                    <p>Plan nutricional y tabla de entrenamiento personalizado para el mes.</p>
                    <p>Contacto vía WhatsApp con la nutricionista tras recibir tu menú para comenzar con tu plan y realizar cualquier ajuste que necesites.</p>
                    <p>Una revisión semanal con la nutricionista vía WhatsApp.</p>
                    <p>Ajuste inicial de tu tabla de entrenamiento personal si requiere.</p>
                    <p>No hay contacto con el entrenador personal.</p>
                </>
            ), precio: <p>Precio: <del>89 €/mes</del>  <b>79 €/mes</b></p>
        },
        {
            title: 'Plan Premium', description: (
                <>
                    <p>Cuestionario inicial para estudiar tu caso.</p>
                    <p>Plan nutricional y tabla de entrenamiento personalizado para el mes.</p>
                    <p>Lista de la compra para facilitar las compras del super.</p>
                    <p>Contacto vía WhatsApp con la nutricionista tras recibir tu menú para comenzar con tu plan y realizar cualquier ajuste que necesites.</p>
                    <p>Revisión semanal con la nutricionista vía WhatsApp (2 revisiones) y videollamada (2 revisiones).</p>
                    <p>Revisión quincenal con tu entrenador vía WhatsApp para resolver dudas y chequear progresos o adaptaciones.</p>
                </>
            ), precio: <p>Precio: <del>119 €/mes</del>  <b>99 €/mes</b></p>
        },
        {
            title: 'Plan VIP', description: (
                <>
                    <p>Cuestionario inicial para estudiar tu caso.</p>
                    <p>Dos planes nutricionales personalizados al mes (se entrega uno al inicio, y el otro en la segunda revisión).</p>
                    <p>Lista de la compra para facilitar las compras del super.</p>
                    <p>Contacto vía WhatsApp al recibir tu menú con la nutricionista para comenzar con tu plan.</p>
                    <p>Revisión semanal con la nutricionista vía WhatsApp (2 revisiones) y videollamada (2 revisiones).</p>
                    <p>Plan de entrenamiento mensual personalizado.</p>
                    <p>Contacto vía videollamada con tu entrenador para resolver dudas iniciales sobre los ejercicios propuestos.</p>
                    <p>Revisión quincenal con tu entrenador vía WhatsApp/videollamada para resolver dudas y chequear progresos o adaptaciones.</p>
                </>
            ), precio: <p>Precio: <del>149 €/mes</del>  <b>129 €/mes</b></p>
        },
    ];

    return (
        <>
            <div className="planes-header">
                <h3>INVIERTE EN TU SALUD</h3>
                <p>La salud es el pilar fundamental para disfrutar de una vida plena y activa. Cuidar tu cuerpo no es solo una cuestión estética, sino una inversión en tu bienestar físico y mental. Un buen estado de salud te permite afrontar el día a día con energía, prevenir enfermedades, y disfrutar de más años con calidad de vida.</p>
                <p>Nuestros planes de entrenamiento y nutrición están diseñados para adaptarse a ti, ayudándote a alcanzar tus metas, ya sea perder peso, ganar masa muscular o mejorar tu rendimiento. ¡Empieza hoy y toma el control de tu salud!</p>
            </div>
            <div className="planes">
                {cardsData.map((card, index) => (
                    <div key={index} className={`card ${activeCard === index ? 'expanded' : ''}`}>
                        <h3>{card.title}</h3>
                        <div className="card-description">{card.description}</div>
                        <p className='precio-card'>{card.precio}</p>
                        <button onClick={() => handleToggle(index)}>
                            {activeCard === index ? 'Ver menos' : 'Contratar'}
                        </button>
                        {activeCard === index && (
                            <div className="card-form">
                                <form ref={form} onSubmit={handleSubmit}>
                                    <input type="hidden" name="plan" value={formData.plan} />
                                    <TextField
                                        label="Nombre*"
                                        name="nombre"
                                        value={formData.nombre}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Apellido*"
                                        name="apellido"
                                        value={formData.apellido}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Correo Electrónico*"
                                        name="correo"
                                        value={formData.correo}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Código Postal*"
                                        name="codigoPostal"
                                        value={formData.codigoPostal}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Teléfono*"
                                        name="telefono"
                                        value={formData.telefono}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Button type="submit" variant="contained" color="primary" className='btnEnviar'>Enviar</Button>
                                </form>
                            </div>
                        )}
                    </div>
                ))}

                {/* Pop-up for message */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>ShapeU!</DialogTitle>
                    <DialogContent>
                        <div dangerouslySetInnerHTML={{ __html: message }}></div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default Planes;
