import React from 'react';
import './Conocenos.css';
import FotoN from '../assets/NutricionistaPersonal.jpg';
import FotoE from '../assets/EntrenadorPersonal.jpg'

const Conocenos = () => {
    const teamMembers = [
        {
            name: 'Irene Camús',
            role: 'Nutricionista',
            description: '¡Hola a tod@s ! Soy amante de la cocina y el deporte, y además, nutricionista experta en pérdida de peso y salud digestiva, con amplia experiencia en ayudarte a transformar tu vida desde la nutrición. Mi trabajo personalizado te guiará hacia tus metas, mejorando tu alimentación de manera saludable y equilibrada. Si quieres mejorar tu composición corporal, controlar alguna patología digestiva o simplemente educar tu paladar, estás en el lugar correcto. Anímate y comencemos el camino hacia tus objetivos junt@s.',
            imageUrl: FotoN
        },
        {
            name: 'Álvaro Navarro',
            role: 'Entrenador Personal',
            description: 'Con una pasión por el fitness y la salud, me especializo en crear programas de entrenamiento adaptados a las necesidades y objetivos individuales de cada cliente. Mi enfoque combina la ciencia del ejercicio con un toque personal, asegurándome de que cada rutina no solo sea efectiva, sino también motivadora y sostenible a largo plazo. En ShapeU!, estoy comprometido en diseñar entrenamientos que no solo transformen tu cuerpo, sino también tu confianza ya sea que busques perder peso, ganar masa muscular, mejorar tu rendimiento deportivo o simplemente llevar un estilo de vida más saludable.',
            imageUrl: FotoE
        }
    ];

    return (
        <>
            <div className="conocenos-header">
                <h3>CÓMO NACE SHAPEU!</h3>
                <p><b>ShapeU! nace de la profunda convicción de que todos merecemos sentirnos bien en nuestra piel y vivir con energía, confianza y alegría.</b> Surgió de la necesidad de ofrecer más que un simple plan de entrenamiento o una dieta; queríamos crear un espacio donde las personas encontrarán apoyo genuino, motivación constante y herramientas prácticas para transformar sus vidas.</p>
                <p>En ShapeU!, creemos que el bienestar es un viaje, no una meta. Un viaje lleno de pequeños logros, de superar obstáculos, y de encontrar la mejor versión de uno mismo. Nacimos para acompañarte en cada paso de ese viaje, para que descubras tu fuerza interior y para que cada día sea una oportunidad de avanzar, crecer y brillar.</p>
                <p>Sabemos que cuando te cuidas, el mundo se transforma contigo, y estamos aquí para asegurarnos de que nunca camines solo en este camino hacia una vida más saludable y feliz, ya que una vida sin salud limita nuestra capacidad de disfrutar plenamente de la vida, convirtiendo tareas cotidianas en desafíos abrumadores. Perdemos la libertad de vivir con plenitud y perseguir nuestros sueños, y en ShapeU! lo sabemos bien, por ello queremos ayudarte.</p>
            </div>
            <div className="conocenos-container">
                {teamMembers.map((member, index) => (
                    <div key={index} className={`card-conocenos ${index % 2 === 0 ? 'left-image' : 'right-image'}`}>
                        <div className="card-imagen">
                            <img src={member.imageUrl} alt={member.name} />
                            <h4>{member.name}</h4>
                        </div>
                        <div className="card-contenido">
                            <h3>{member.role}</h3>
                            <p>{member.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </>

    );
};

export default Conocenos;
