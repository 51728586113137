import React from 'react';
import './Legal.css';

const Legal = () => {
    return (
        <div className="legal-container">
            <h1>Política de Privacidad. Términos y Condiciones</h1>

            <section id="privacidad-terminos">
                <h2>Pol&iacute;tica de privacidad</h2>
                <p><strong>1. Informaci&oacute;n a las personas usuarias</strong></p>
                <p><strong>&iquest;Qui&eacute;n es responsable del tratamiento de tus datos personales?</strong></p>
                <p>&Aacute;lvaro Navarro Espinosa e Irene Cam&uacute;s, actuando bajo la marca comercial *ShapeU!*, son los RESPONSABLES del tratamiento de los datos personales del USUARIO y le informan de que estos datos ser&aacute;n tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Org&aacute;nica 3/2018, de 5 de diciembre (LOPDGDD).</p>

                <p><strong>&iquest;Para qu&eacute; tratamos tus datos personales?</strong></p>
                <p>Para mantener una relaci&oacute;n comercial con el usuario. Las operaciones previstas para realizar el tratamiento son:</p>
                <p>- Remisi&oacute;n de comunicaciones comerciales publicitarias por e-mail, fax, SMS, MMS, redes sociales o cualquier otro medio electr&oacute;nico o f&iacute;sico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas comunicaciones ser&aacute;n realizadas por los RESPONSABLES y estar&aacute;n relacionadas con sus productos y servicios, o de sus colaboradores o proveedores, con los que estos hayan alcanzado alg&uacute;n acuerdo de promoci&oacute;n. En este caso, los terceros nunca tendr&aacute;n acceso a los datos personales.</p>
                <p>- Realizar estudios de mercado y an&aacute;lisis estad&iacute;sticos.</p>
                <p>- Tramitar encargos, solicitudes, dar respuesta a las consultas o cualquier tipo de petici&oacute;n que sea realizada por el USUARIO a trav&eacute;s de cualquiera de las formas de contacto que se ponen a su disposici&oacute;n en la p&aacute;gina web de los RESPONSABLES.</p>
                <p>- Remitir el bolet&iacute;n informativo online sobre novedades, ofertas y promociones en nuestra actividad.</p>

                <p><strong>&iquest;Por qu&eacute; motivo podemos tratar tus datos personales?</strong></p>
                <p>Porque el tratamiento est&aacute; legitimado por el art&iacute;culo 6 del GDPR de la siguiente forma:</p>
                <p>- Con el consentimiento del USUARIO: remisi&oacute;n de comunicaciones comerciales y del bolet&iacute;n informativo.</p>
                <p>- Por inter&eacute;s leg&iacute;timo de los RESPONSABLES: realizar estudios de mercado, an&aacute;lisis estad&iacute;sticos, etc. y tramitar encargos, solicitudes, etc. a petici&oacute;n del USUARIO.</p>
                <p><strong>&iquest;Durante cu&aacute;nto tiempo guardaremos tus datos personales?</strong></p>
                <p>Se conservar&aacute;n durante no m&aacute;s tiempo del necesario para mantener el fin del tratamiento o existan prescripciones legales que dictaminen su custodia. Cuando ya no sea necesario para ello, se suprimir&aacute;n con medidas de seguridad adecuadas para garantizar el anonimato de los datos o la destrucci&oacute;n total de los mismos.</p>

                <p><strong>&iquest;A qui&eacute;n facilitamos tus datos personales?</strong></p>
                <p>No est&aacute; prevista ninguna comunicaci&oacute;n de datos personales a terceros salvo por obligaci&oacute;n legal o, si fuese necesario para el desarrollo y ejecuci&oacute;n de las finalidades del tratamiento, a nuestros proveedores de servicios relacionados con comunicaciones, con los cuales los RESPONSABLES tienen suscritos los contratos de confidencialidad y de encargado de tratamiento exigidos por la normativa vigente de privacidad.</p>

                <p><strong>&iquest;Cu&aacute;les son tus derechos?</strong></p>
                <p>Los derechos que asisten al USUARIO son:</p>
                <p>- Derecho a retirar el consentimiento en cualquier momento.</p>
                <p>- Derecho de acceso, rectificaci&oacute;n, portabilidad y supresi&oacute;n de sus datos, y de limitaci&oacute;n u oposici&oacute;n a su tratamiento.</p>
                <p>- Derecho a presentar una reclamaci&oacute;n ante la autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.</p>

                <p><strong>2. Datos de contacto para ejercer sus derechos</strong></p>
                <p>- Nombre de dominio: shapeu!.com</p>
                <p>- Nombre comercial: ShapeU!</p>
                <p>- Nombres: Irene Cam&uacute;s Urbano</p>
                <p>- Tel&eacute;fono: +34 608382766</p>
                <p>- E-mail: shapeu.contact@gmail.com</p>

                <p><strong>3. Car&aacute;cter obligatorio o facultativo de la informaci&oacute;n facilitada por las personas usuarias</strong></p>
                <p>Los USUARIOS, mediante la marcaci&oacute;n de las casillas correspondientes y la entrada de datos en los campos, marcados con un asterisco (*) en el formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de forma libre e inequ&iacute;voca, que sus datos son necesarios para atender su petici&oacute;n por parte de los prestadores, siendo voluntaria la inclusi&oacute;n de datos en los campos restantes. El USUARIO garantiza que los datos personales facilitados a los RESPONSABLES son veraces y se hace responsable de comunicar cualquier modificaci&oacute;n de los mismos. Los RESPONSABLES informan de que todos los datos solicitados a trav&eacute;s del sitio web son obligatorios, ya que son necesarios para la prestaci&oacute;n de un servicio &oacute;ptimo al USUARIO. En caso de que no se faciliten todos los datos, no se garantiza que la informaci&oacute;n y servicios facilitados sean completamente ajustados a sus necesidades.</p>
                <p><strong>4. Medidas de seguridad</strong></p>
                <p>De conformidad con lo dispuesto en las normativas vigentes en protecci&oacute;n de datos personales, los RESPONSABLES est&aacute;n cumpliendo con todas las disposiciones de las normativas GDPR y LOPDGDD para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos en el art&iacute;culo 5 del GDPR, por los cuales son tratados de manera l&iacute;cita, leal y transparente en relaci&oacute;n con el interesado y adecuados, pertinentes y limitados a lo necesario en relaci&oacute;n con los fines para los que son tratados.</p>

                <p>Los RESPONSABLES garantizan que han implementado pol&iacute;ticas t&eacute;cnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el GDPR y la LOPDGDD con el fin de proteger los derechos y libertades de los USUARIOS y les han comunicado la informaci&oacute;n adecuada para que puedan ejercerlos.</p>
                <p>Para m&aacute;s informaci&oacute;n sobre las garant&iacute;as de privacidad, puedes dirigirte a los RESPONSABLES a trav&eacute;s de los datos de contacto facilitados en el punto 2 de esta misma p&aacute;gina.</p>

                <h2>T&eacute;rminos y condiciones</h2>
                <p>Condiciones de uso: Nuestros &laquo;Servicios&raquo; comprenden nuestro sitio web, nuestras redes sociales y los servicios de coaching a los que se conecte a través de nuestro sitio web o red social. Lea atentamente estas Condiciones de uso (&laquo;Condiciones&raquo;).</p>
                <p>Al utilizar nuestros Servicios, usted acepta estas Condiciones. Si no está de acuerdo con ellas, no descargue ni utilice nuestros Servicios.</p>
                <p><strong>1. QUIÉNES SOMOS Y PARA QUE SIRVE ESTE CONTRATO:</strong></p>
                <p>Somos &Aacute;lvaro Navarro Espinosa e Irene Cam&uacute;s Urbano, entrenador personal y nutricionista.</p>
                <p>Estas Condiciones rigen el acceso y el uso de nuestros Servicios. Estas Condiciones establecen sus derechos y responsabilidades jurídicas; nuestros derechos y responsabilidades jurídicas, y determinada información clave exigida por la ley.</p>
                <p><strong>2. COMO PUEDE CONTACTARNOS</strong></p>
                <p>Contactar con nosotros. Estamos aquí para ayudarle a mejorar su salud. Si desea ponerse en contacto con nosotros por cualquier motivo, envíenos un correo electrónico a shapeu.contact@gmail.com.</p>
                <p>Cómo nos comunicaremos con usted. Si tenemos que ponernos en contacto con usted, lo haremos por correo electrónico, whatsapp o correo postal prefranqueado, utilizando los datos de contacto que nos haya proporcionado.</p>
                <p>Al registrarse para que nos pongamos en contacto con usted en nuestro sitio web, usted confirma que nosotros (o nuestro representante) podemos ponernos en contacto con usted para informarle sobre nuestros servicios.</p>
                <p><strong>3. CÓMO PUEDE UTILIZAR NUESTROS SERVICIOS</strong></p>
                <p>A cambio de aceptar el cumplimiento de estas Condiciones, usted puede:</p>

                <p>- utilizar los servicios únicamente con fines personales.</p>
                <p>- pedirnos servicios de nutrici&oacute;n y entrenamiento personal, que podremos ofrecerle en las condiciones que se indican a continuación:<p>- Debe tener al menos 18 años o contar con el consentimiento de sus padres para aceptar estas Condiciones y descargar o utilizar nuestros Servicios. Si es menor de 18 años y cuenta con el consentimiento paterno/materno, le recomendamos que sus progenitores participen en la sesión de nutrici&oacute;n o entrenamiento personal y le aconsejen sobre el uso de nuestros Servicios.Si es menor de 18 años y no ha obtenido el consentimiento paterno/materno para participar en una sesión de nuestros servicios con nosotros, no podremos aceptarle como cliente.</p><p>- Se puede acceder en línea a cualquier plan proporcionado como parte de los Servicios (por ejemplo, planes de comidas y de ejercicios). El acceso a los planes caduca a la finalización de los Servicios (por lo tanto, le recomendamos que guarde o imprima los planes según le convenga).</p></p>
                <p><strong>4. EL USO DE NUESTROS SERVICIOS CORRE BAJO SU ENTERA RESPONSABILIDAD (NO OFRECEMOS ASESORAMIENTO MÉDICO)</strong></p>
                <p>Antes de iniciar un programa de entrenamiento o dieta, debe consultar a su médico de cabecera o profesional médico para determinar si es adecuado para usted. Si sufre desvanecimientos, mareos, dificultad para respirar o dolor durante el ejercicio, deténgase de inmediato y consulte a un médico. El ejercicio conlleva cierto riesgo de lesiones, y usted realiza estas actividades bajo su responsabilidad. El uso de cualquier plan de comidas corre bajo su entera responsabilidad, y debe revisar el contenido para verificar que cualquier alergia o intolerancia que pueda tener se ajuste al plan. Nuestros Servicios no sustituyen el tratamiento ni el consejo médico. Nuestros Servicios se ofrecen únicamente con fines de información general y entretenimiento.</p>
                <p>Tenga en cuenta la siguiente lista de riesgos y requisitos, no exhaustiva, al utilizar nuestros Servicios:</p>
                <p>&bull; Las alergias e intolerancias que puedan influir en la elaboración de nuestros planes de nutrición deben comunicarse siempre en el cuestionario.</p>
                <ul>
                    <li>
                        <p>Las lesiones, enfermedades y dolencias que puedan influir en la elaboración de nuestros planes de entrenamiento deben comunicarse siempre en el cuestionario.</p>
                    </li>
                    <li>
                        <p>Revise siempre detenidamente el contenido/ingredientes de su plan de comidas para comprobar si hay alimentos a los que sea alérgico o intolerante.</p>
                    </li>
                    <li>
                        <p>Asegúrese siempre de disponer de espacio suficiente para realizar las actividades.</p>
                    </li>
                    <li>
                        <p>Asegúrese de que nada de lo que le rodea pueda causarle daño o impedirle el movimiento.</p>
                    </li>
                    <li>
                        <p>Si decide utilizar un equipo de fitness, lo hará bajo su propia responsabilidad.</p>
                    </li>
                    <li>
                        <p>En algunos casos, la restricción de la ingesta de kcal puede provocar ansiedad, trastornos alimentarios, depresión, fatiga y otros problemas de salud mental y física relacionados.</p>
                    </li>
                </ul>
                <p><strong>5. ADVERTENCIAS IMPORTANTES</strong></p>
                <p>Queda FUERA de nuestro ámbito de actuación RECETAR un suplemento específico o una dosis concreta a nuestros clientes.</p>
                <p>En concreto, nosotros:</p>
                <ul>
                    <li>
                        <p>No prescribimos dietas ni suplementos para tratar afecciones médicas y clínicas.</p>
                    </li>
                    <li>
                        <p>No realizamos diagnósticos de enfermedades. Solo ofrecemos inspiración y orientación para la planificación de comidas, y nuestros planes de comidas están pensados para servir como estímulo para comer proteínas magras y verduras ricas en nutrientes y adaptarse a sus necesidades diarias de macronutrientes. Sin embargo, no prescribimos ningún plan de comidas ni intentamos diagnosticar ni tratar ninguna enfermedad que padezca, el tratamiento siempre lo dictar&aacute; tu m&eacute;dico.</p>
                    </li>
                </ul>
                <p><strong>6. SOLICITUD DE SERVICIOS</strong></p>
                <p>Puede solicitar nuestros servicios inscribiéndose a través del formulario de contacto de nuestro sitio web. Nosotros nos pondremos en contacto con usted para obtener más información sobre usted y sus deseos. Si existe un acuerdo entre nosotros, se le enviará un correo electrónico con los datos de su servicio acordado entre nosotros, así como un n&uacute;mero de cuenta para el pago. La aceptación de su pedido tendrá lugar cuando recibamos un correo con un resguardo de la transferencia realizada abonando el servicio acordado, comprobemos que es correcto, y le enviemos un correo electrónico para aceptarlo, momento en el que entrará en vigor el contrato de servicios de coaching entre usted y nosotros.</p>
                <p>Si no podemos aceptar su pedido, le informaremos de ello y no le cobraremos por los servicios. Esto puede deberse a límites inesperados en nuestros recursos que no podíamos prever razonablemente, a que una referencia de crédito que hemos obtenido para usted no cumple con nuestros requisitos mínimos o a que hemos detectado un error en el precio o la descripción de los servicios.</p>
                <p>El contrato que usted suscriba puede estar en los siguientes idiomas: inglés y español. Póngase en contacto con nosotros, si desea recibir el contrato en alguno de estos idiomas.</p>
                <p>Hasta que el pedido se haya aceptado, puede modificarlo en cualquier momento enviando un correo electrónico a shapeu.contact@gmail.com</p>
                <p>Una vez aceptado el pedido, se le enviará directamente por correo electrónico una copia de las presentes Condiciones junto con la confirmación del pedido. Una vez completado el pedido, el servicio estará a su disposición según se detalla en la confirmación del pedido.</p>
                <p>El Servicio continuará (y estas Condiciones seguirán estando vigentes) mientras dure su programa.</p>
                <p><strong>7. POL&Iacute;TICA DE PRECIOS Y CONDICIONES DE PAGO</strong></p>
                <p>Todas las tasas, cargos, IVA y gastos de envío (si corresponde) están incluidos en el precio (que se especificará con más detalle durante el pago). El pago de los servicios deber&aacute; realizarse a trav&eacute;s de Bizum o transferencia bancaria una vez que el cliente solicite un servicio (por adelantado).&nbsp;</p>
                <p>Los planes de entrenamiento y/o dieta personalizados no ser&aacute;n enviados hasta que hayamos recibido y confirmado el pago en su totalidad. Es responsabilidad del cliente asegurarse de que el pago se realice correctamente y en un plazo razonable para evitar retrasos en la entrega del plan.</p>
                <p>Las condiciones de pago dependerán de los servicios precisos acordados entre usted y nosotros. En la confirmación del pedido se incluirá una copia de estas condiciones de pago.</p>
                <ul>
                    <li>
                        <p><strong>Verificaci&oacute;n de Pago</strong><p>Todos los pagos ser&aacute;n verificados antes de la entrega de cualquier servicio. Nos reservamos el derecho de solicitar comprobantes adicionales de pago si es necesario, como el recibo de transferencia o capturas de pantalla del pago realizado a trav&eacute;s de Bizum.</p></p>
                    </li>
                    <li>
                        <p><strong>Pol&iacute;tica de No Reembolsos</strong><p>Una vez que el pago haya sido confirmado y el servicio entregado, no se emitir&aacute;n reembolsos bajo ninguna circunstancia. Esto incluye situaciones en las que el cliente decide no utilizar el servicio, no queda satisfecho con el mismo, o intenta revocar el pago una vez recibido el plan.&nbsp;</p></p>
                    </li>
                    <li>
                        <p><strong>Retrasos en la Confirmaci&oacute;n de Pagos</strong><p>Nos reservamos el derecho de retener el env&iacute;o del plan hasta que el pago sea verificado completamente, especialmente en casos donde existan dudas sobre la legitimidad de la transacci&oacute;n. El cliente ser&aacute; informado en caso de cualquier retraso debido a la verificaci&oacute;n del pago.</p></p>
                    </li>
                </ul>

                <p><strong>8.ACTUALIZACIONES Y CAMBIOS EN LOS SERVICIOS</strong></p>
                <p>Cuando lo consideremos oportuno, podemos actualizar y cambiar los Servicios para mejorar el rendimiento y la funcionalidad, reflejar los cambios en el sistema operativo o abordar los problemas de seguridad. Asegúrese de aceptar todas las actualizaciones relacionadas con los Servicios.&nbsp;</p>
                <p><strong>9.SU CUENTA</strong></p>
                <p>Debe mantener la confidencialidad de los datos de acceso, tales como el nombre de usuario, la contraseña y las preguntas de seguridad, y no revelárselos a nadie. Si tiene algún motivo para creer que se han visto comprometidos, deberá restablecerlos de inmediato utilizando nuestra función &laquo;He olvidado mi contraseña&raquo;.</p>
                <p>Bajo ninguna circunstancia debe permitir que otra persona acceda a la plataforma con sus datos de inicio de sesión. Esto no solo afectará a la funcionalidad de los Servicios, ya que las estadísticas y el seguimiento no serán correctos, sino que también será responsable de cualquier acción realizada por una persona que utilice su cuenta de usuario, sea o no con su conocimiento o consentimiento.</p>
                <p>Permitir que otros accedan a los Servicios utilizando sus datos de inicio de sesión constituye un incumplimiento de las presentes Condiciones y puede dar lugar a la suspensión de su derecho a utilizar los Servicios o a que pongamos fin a su derecho a utilizarlos.</p>
                <p><strong>10. NO PODR&Aacute; TRANSFERIR LOS SERVICIOS A OTRA PERSONA</strong></p>
                <p>Le otorgamos el derecho a utilizar los Servicios a título personal, tal y como se establece en las presentes Condiciones. No podrá transferir los Servicios a otra persona, ya sea a cambio de dinero, de cualquier otra cosa o de forma gratuita.</p>
                <p><strong>11. PRIVACIDAD</strong></p>
                <p>Solo utilizamos la información personal que recopilamos a través del uso que hace de nuestros Servicios, en las formas establecidas en nuestro Aviso de privacidad.</p>
                <p>Las transmisiones por internet nunca son completamente privadas ni seguras. Esto significa que existe el riesgo de que cualquier información que envíe utilizando nuestros Servicios (incluyendo nuestro sitio web) pueda ser leída o interceptada por terceros, incluso si existe un aviso especial de que una transmisión en particular está encriptada.</p>
                <p><strong>12. DERECHOS DE PROPIEDAD INTELECTUAL</strong></p>
                <p>Todos los derechos de propiedad intelectual de los Servicios (incluyendo nuestro sitio web) en todo el mundo nos pertenecen a nosotros y a nuestros licenciados. Los derechos de los Servicios se le otorga a usted (no se le venden) con licencia. Usted no tiene ningún derecho de propiedad intelectual sobre los Servicios, salvo el derecho a utilizarlos de conformidad con las presentes Condiciones.</p>
                <p>Estas Condiciones le otorgan un derecho personal, intransferible y no exclusivo a utilizar nuestros Servicios. Le otorgamos este derecho con el único propósito de recibir los Servicios según lo permitido en estas Condiciones.</p>
                <p>Nuestros Servicios (incluyendo nuestro sitio web) están protegidos por derechos de autor, marcas comerciales y otras leyes de propiedad intelectual. Nada de lo dispuesto en las presentes Condiciones le da derecho a usar el nombre de ShapeU!, las marcas comerciales, logotipos, nombres de dominio, otras características distintivas de la marca ni otros derechos de propiedad (ya sean nuestros o de nuestros licenciantes). Todos los derechos, títulos y beneficios en nuestros Servicios (excepto el contenido proporcionado por usted) son y seguirán siendo propiedad exclusiva nuestra y de nuestros licenciados.</p>
                <p>Los comentarios, opiniones o sugerencias que pueda proporcionar con respecto a nuestros Servicios (incluyendo nuestro sitio web) son totalmente voluntarios y podremos utilizarlos libremente según consideremos oportuno y sin ninguna obligación para con usted.</p>
                <p>Trataremos cualquier contenido que cargue a través de los Servicios como si nos perteneciera. No debe cargar ningún contenido a menos que tenga derecho a hacerlo y dicho contenido cumpla con el apartado Restricciones de uso aceptable, que aparece a continuación.</p>
                <p>Si cualquier otra persona sugiere que nuestros Servicios o su uso según las presentes Condiciones infringen su propiedad intelectual, somos responsables de investigar y defender dicha reclamación.</p>
                <p><strong>13. RESTRICCIONES DE LICENCIA</strong></p>
                <p>Usted acepta:</p>
                <ul>
                    <li>
                        <p>No conceder sublicencias ni poner a disposición de ninguna otra forma nuestros Servicios (cualquier plan de entrenamiento o dieta) a ninguna persona sin nuestro consentimiento previo por escrito;</p>
                    </li>
                    <li>
                        <p>No copiar los Servicios (nuestro sitio web), excepto como parte del uso normal de los Servicios o cuando sea necesario con fines de copia de seguridad o seguridad operativa;</p>
                    </li>
                    <li>
                        <p>No traducir, fusionar, adaptar, variar, alterar o modificar ni en parte ni en su totalidad los Servicios (sitio web);</p>
                    </li>
                    <li>
                        <p>No combinar ni incorporar los Servicios en ningún otro programa, excepto cuando sea necesario para utilizar los Servicios en dispositivos, según lo permitido en las presentes Condiciones;</p>
                    </li>
                    <li>
                        <p>No desensamblar, descompilar, aplicar ingeniería inversa ni crear trabajos derivados basados en parte o en la totalidad de los Servicios, ni intentar hacer nada de esto, salvo en la medida en que lo permita expresamente la legislación vigente;</p>
                    </li>
                    <li>
                        <p>Cumplir con todas las leyes y reglamentos vigentes aplicables a la tecnología utilizada o compatible con los Servicios.</p>
                    </li>
                </ul>
                <p><strong>14. RESTRICCIONES DE USO ACEPTABLE</strong><p>Puede utilizar nuestros Servicios (incluyendo nuestro sitio web) únicamente con fines legales. No debe:</p></p>
                <ul>
                    <li>
                        <p>utilizar los Servicios de ninguna manera ilícita, para ningún fin ilícito o de ninguna manera que sea incompatible con las presentes Condiciones;</p>
                    </li>
                    <li>
                        <p>actuar de forma fraudulenta o maliciosa;</p>
                    </li>
                    <li>
                        <p>acceder, utilizar, distribuir o transmitir códigos maliciosos, como virus o datos dañinos, a ningún Servicio (incluyendo el sitio web) ni a ningún sistema operativo;</p>
                    </li>
                    <li>
                        <p>infringir nuestros derechos de propiedad intelectual o los de terceros en relación con el uso de los Servicios;</p>
                    </li>
                    <li>
                        <p>transmitir ningún material difamatorio, discriminatorio, amenazador, obsceno, sexualmente explícito, ofensivo o censurable en relación con el uso de los Servicios;</p>
                    </li>
                    <li>
                        <p>utilizar los Servicios de una manera que pueda dañar, sobrecargar, perjudicar o comprometer nuestros sistemas o seguridad o interferir con otros usuarios, ni recopilar ni recoger información o datos de ningún Servicio o de nuestros sistemas, ni intentar descifrar ninguna transmisión hacia o desde los servidores que ejecuten los Servicios. Además, si participa en la funcionalidad de chat del grupo en nuestra aplicación, no debe:</p>
                    </li>
                </ul>
                <p>&middot;difundir información peligrosa o errónea (por ejemplo, inspirar a otros usuarios en la aplicación para que no coman);</p>
                <p>&middot;tratar ni organizar actividades delictivas;</p>
                <p>&middot;Enviar correo basura/promociones, ni participar en discursos de odio, negatividad corporal o intimidación.</p>
                <p>Nos reservamos el derecho a eliminar su contenido y/o cuenta de forma permanente si descubrimos que infringe estas normas.</p>
                <p><strong>15. NUESTRA RESPONSABILIDAD POR LAS PÉRDIDAS O DAÑOS SUFRIDOS POR USTED</strong></p>
                <p>Limitaciones de los Servicios. Nuestros Servicios no est&aacute;n dise&ntilde;ados para cubrir todas sus necesidades. Aunque ofrecemos rutinas de ejercicio o planes de dieta personalizados, usted acepta que los sigue bajo su propia responsabilidad. Si tiene alguna inquietud sobre estos Servicios o su salud, es recomendable que consulte a su m&eacute;dico de cabecera o a un profesional de la salud.</p>
                <p>Usted utiliza la informaci&oacute;n proporcionada a trav&eacute;s de los Servicios bajo su propia responsabilidad. Aunque nos esforzamos razonablemente por mantener actualizada la informaci&oacute;n que ofrecemos, no garantizamos ni aseguramos, de manera expl&iacute;cita o impl&iacute;cita, que dicha informaci&oacute;n sea precisa, completa o est&eacute; al d&iacute;a. Es posible que reciba asesoramiento de terceros a trav&eacute;s de los Servicios; no obstante, no nos hacemos responsables de cualquier consejo recibido de terceros que utilicen estos Servicios.</p>
                <p>No somos responsables de los retrasos que est&eacute;n fuera de nuestro control. Si la prestaci&oacute;n de nuestros servicios se retrasa debido a un evento fuera de nuestro alcance, nos pondremos en contacto con usted lo antes posible para informarle y tomaremos medidas para reducir el impacto del retraso. Siempre que actuemos de esta manera, no seremos responsables de los retrasos causados por dicho evento. Sin embargo, si existe el riesgo de un retraso significativo, puede comunicarse con nosotros para discutir sus posibles derechos de cancelar el contrato y recibir un reembolso por cualquier servicio pagado que no haya recibido.</p>

                <p><strong>16. LIMITACIONES DE RESPONSABILIDAD</strong></p>
                <p>En aras de la claridad, las presentes Condiciones no limitan nuestra responsabilidad por fraude, declaración falsa fraudulenta, muerte, lesiones personales ni ninguna otra responsabilidad en la medida en que la legislación vigente prohíba dicha limitación.</p>
                <p>Limitación de daños</p>
                <p>En ningún caso shapeu!, sus directores, ejecutivos, accionistas, empleados, agentes o afiliados serán responsables ante la otra parte por ningún daño consecuente, incidental, indirecto, especial, punitivo o ejemplar, costes o gastos (incluyendo, a título enunciativo, la pérdida de beneficios, la pérdida de ingresos y/o la pérdida de ahorros), ya sea basada en una reclamación o acción de contrato, garantía, negligencia, responsabilidad estricta o de otro tipo, que surja de un incumplimiento o presunto incumplimiento de las presentes condiciones, o el uso de cualquier servicio prestado en virtud de la presente.</p>
                <p>Tope de responsabilidad</p>
                <p>En ningún caso nuestra responsabilidad ante usted por puede corregir, le daremos una oportunidad razonable para hacerlo. Si ponemos fin a su derecho a utilizar los Servicios de esta manera, podremos retener los pagos que ya haya realizado para cubrir nuestros costes y/o compensar nuestras pérdidas derivadas del incumplimiento por su parte.</p>
                <p>Podemos rescindir el contrato si resulta imposible o inviable prestar nuestros Servicios (o una parte de los Servicios). Si decidimos suspender o dejar de prestar cualquier parte de nuestros Servicios de esta manera, se lo haremos saber. Si ha pagado nuestros Servicios por adelantado, es posible que tenga derecho a un reembolso proporcional del dinero que ha pagado por adelantado y por el tiempo que no haya podido utilizar nuestros Servicios porque hemos rescindido el<span>&nbsp;contrato.</span></p>
                <p>Ejercer su derecho de desistimiento. Al aceptar estas condiciones, usted acepta que accederá a servicios digitales en forma de programas de entrenamiento y consejos y planes de nutrición, ya sea inmediatamente o a partir de la fecha detallada en su formulario de pedido.</p>
                <p>Tiene el derecho de desistimiento con arreglo a las normas generales de la legislación local sobre contratos de consumo. Este derecho se aplica sin perjuicio de cualquier otro recurso legal que pueda tener a su disposición en virtud de la legislación local.</p>
                <p>Sus derechos por ley. Tenemos la obligación legal de prestar unos servicios que sean acordes con este contrato. Nada de lo dispuesto en las presentes Condiciones afectará a los derechos que tenga por ley.</p>

                <p><strong>20. PODEMOS TRANSFERIR ESTE CONTRATO A UN TERCERO</strong><p>Podemos transferir nuestros derechos y obligaciones bajo estas Condiciones a otra organizaci&oacute;n, por ejemplo, en caso de ser adquiridos por un tercero, siempre que continuemos siendo responsables ante usted de que el nuevo titular cumpla con estas Condiciones. Tambi&eacute;n podemos transferir nuestros derechos y obligaciones en el contexto de la venta de la empresa. Le notificaremos por escrito si esto ocurre y nos aseguraremos de que la transferencia no afecte sus derechos bajo estas condiciones.</p></p>
                <p><strong>21. NECESITA NUESTRO CONSENTIMIENTO PARA TRANSFERIR SUS DERECHOS A UN TERCERO</strong></p>
                <p>Solo puede transferir sus derechos u obligaciones en virtud de las presentes Condiciones a un tercero si lo aceptamos por escrito.</p>
                <p><strong>22. SI UN TRIBUNAL DECLARA ILEGAL UNA PARTE DE ESTE CONTRATO, EL RESTO CONTINUARÁ EN VIGOR</strong></p>
                <p>Cada uno de los apartados de las presentes Condiciones funciona por separado. Si un tribunal o autoridad competente decide que alguno de los apartados es ilegal, los restantes seguirán teniendo plena vigencia.</p>
                <p><strong>23. INCLUSO SI NOS RETRASAMOS EN LA APLICACIÓN DE ESTAS CONDICIONES, PODEMOS IMPLEMENTARLAS MÁS ADELANTE</strong></p>
                <p>Incluso si nos retrasamos en la aplicación de las presentes condiciones, podemos implementarlas más adelante. Si no insistimos inmediatamente en que haga cualquier cosa que deba hacer en virtud de estas Condiciones, o si nos demoramos en tomar medidas contra usted en relación con su incumplimiento de las mismas, eso no significará que no tenga que hacer esas cosas y no nos impedirá tomar medidas contra usted en una fecha posterior.</p>
                <p><strong>24. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</strong></p>
                <p>Las presentes Condiciones se rigen por la legislación española, siempre y cuando esta elección de fuero no afecte a la protección de la que usted disfruta en virtud de la legislación obligatoria de su país de residencia. Puede emprender acciones judiciales con respecto a los Servicios en los tribunales competentes.</p>
                <p><strong>25. RESOLUCIÓN ALTERNATIVA DE LITIGIOS</strong></p>
                <p>Si desea presentar una reclamación sobre el Servicio, no dude en ponerse en contacto con nosotros escribiendo un correo electrónico a shapeu.contact@gmail.com. Su reclamación se tramitará según las prácticas del mercado, sin demoras indebidas.</p>
                <p>La resolución alternativa de litigios es un proceso en el que un organismo independiente examina los hechos de un litigio y trata de resolverlo, sin que usted tenga que acudir a los tribunales. Si no está satisfecho con la forma en que hemos gestionado cualquier reclamación, puede enviar el litigio para su resolución en línea a la plataforma de&nbsp;<span>Resolución de litigios en línea de la Comisión Europea.</span></p>
                <p>Última actualización: 3/09/2024</p>


                <p><strong><span>Aviso Legal</span></strong></p>
                <p>Alvaro Navarro Espinosa e Irene Cam&uacute;s Urbano, responsables del sitio web, en adelante RESPONSABLES, ponen a disposici&oacute;n de los usuarios el presente documento, con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico (LSSICE), as&iacute; como informar a todos los usuarios del sitio web respecto a cu&aacute;les son las condiciones de uso.Toda persona que acceda a este sitio web asume el papel de usuario, comprometi&eacute;ndose a la observancia y cumplimiento riguroso de las disposiciones aqu&iacute; dispuestas, as&iacute; como a cualquier otra disposici&oacute;n legal que fuera de aplicaci&oacute;n.</p>
                <p>Los RESPONSABLES se reservan el derecho de modificar cualquier tipo de informaci&oacute;n que pudiera aparecer en el sitio web, sin que exista obligaci&oacute;n de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendi&eacute;ndose como suficiente con la publicaci&oacute;n en el&nbsp;<span>sitio web de ShapeU!.</span></p>
                <p><strong>DATOS IDENTIFICATIVOS</strong></p>
                <ul>
                    <li>
                        <p>Nombre: Irene Camús Urbano.</p>
                    </li>
                    <li>
                        <p>NIF: 48771246E</p>
                    </li>
                    <li>
                        <p>Direcci&oacute;n postal: Calle Escultor Navarro Santafé, 24 B Villena (03400)</p>
                    </li>
                    <li>
                        <p>Datos de contacto: shapeu.contact@gmail.com</p>
                    </li>
                    <li>
                        <p>Datos del colegio profesional al que est&aacute;s adscrito</p>
                    </li>
                    <li>
                        <p>N&uacute;mero de colegiada: CV00975</p>
                    </li>
                    <li>
                        <p>Grado en Nutrición Humana y Dietética - Universidad de Alicante.</p>
                    </li>
                    <li>
                        <p>Estatuto profesional que rige la actividad: Ley 5/2009, de 30 de junio, de creación del Colegio Oficial de Dietistas y Nutricionistas de la Comunitat Valenciana.</p>
                    </li>
                </ul>
                <p><strong>2. OBJETO</strong></p>
                <p>A trav&eacute;s del Sitio Web, les ofrecemos a los Usuarios la posibilidad de acceder a la informaci&oacute;n sobre nuestros servicios.</p>
                <p><strong>3. PRIVACIDAD Y TRATAMIENTO DE DATOS</strong></p>
                <p>El acceso a los contenidos y/o elementos de la Web, no otorga al usuario el derecho de alteraci&oacute;n, modificaci&oacute;n, explotaci&oacute;n, reproducci&oacute;n, distribuci&oacute;n o comunicaci&oacute;n p&uacute;blica de dichos contenidos, derechos y/o elementos.&nbsp;</p>
                <p>El usuario se compromete a utilizar los contenidos y/o elementos a los que acceda a trav&eacute;s de los servicios de la Web para su propio uso y necesidades, y a no realizar en ning&uacute;n caso una explotaci&oacute;n comercial, directa o indirecta de los mismos. Se proh&iacute;be el uso comercial de los contenidos del sitio web sin previa consulta previa y autorizaci&oacute;n por parte de los RESPONSABLES.</p>

                <p><strong>4. PROPIEDAD INDUSTRIAL E INTELECTUAL</strong></p>
                <p>El Usuario reconoce y acepta que todos los contenidos que se muestran en el Espacio Web y en especial, dise&ntilde;os, textos, im&aacute;genes, logos, iconos, botones, software, nombres comerciales, marcas, o cualesquiera otros signos susceptibles de utilizaci&oacute;n industrial y/o comercial est&aacute;n sujetos a derechos de Propiedad Intelectual y todas las marcas, nombres comerciales o signos distintivos, todos los derechos de propiedad industrial e intelectual, sobre los contenidos y/o cualesquiera otros elementos insertados en el p&aacute;gina, que son propiedad exclusiva de la empresa y/o de terceros, quienes tienen el derecho exclusivo de utilizarlos en el tr&aacute;fico econ&oacute;mico. Por todo ello el Usuario se compromete a no reproducir, copiar, distribuir, poner a disposici&oacute;n o de cualquier otra forma comunicar p&uacute;blicamente, transformar o modificar tales contenidos manteniendo indemne a la empresa de cualquier reclamaci&oacute;n que se derive del incumplimiento de tales obligaciones.</p>
                <p>En ning&uacute;n caso el acceso al Espacio Web implica ning&uacute;n tipo de renuncia, transmisi&oacute;n, licencia o cesi&oacute;n total ni parcial de dichos derechos, salvo que se establezca expresamente lo contrario. Las presentes Condiciones Generales de Uso del Espacio Web no confieren a los Usuarios ning&uacute;n otro derecho de utilizaci&oacute;n, alteraci&oacute;n, explotaci&oacute;n, reproducci&oacute;n, distribuci&oacute;n o comunicaci&oacute;n p&uacute;blica del Espacio Web y/o de sus Contenidos distintos de los aqu&iacute; expresamente previstos. Cualquier otro uso o explotaci&oacute;n de cualesquiera derechos estar&aacute; sujeto a la previa y expresa autorizaci&oacute;n espec&iacute;ficamente otorgada a tal efecto por la empresa o el tercero titular de los derechos afectados.</p>
                <p>Los contenidos, textos, fotograf&iacute;as, dise&ntilde;os, logotipos, im&aacute;genes, programas de ordenador, c&oacute;digos fuente y, en general, cualquier creaci&oacute;n intelectual existente en este Espacio, as&iacute; como el propio Espacio en su conjunto, como obra art&iacute;stica multimedia, est&aacute;n protegidos como derechos de autor por la legislaci&oacute;n en materia de propiedad intelectual. La empresa es titular de los elementos que integran el dise&ntilde;o gr&aacute;fico del Espacio Web, lo men&uacute;s, botones de navegaci&oacute;n, el c&oacute;digo HTML, los textos, im&aacute;genes, texturas, gr&aacute;ficos y cualquier otro contenido del Espacio Web o, en cualquier caso dispone de la correspondiente autorizaci&oacute;n para la utilizaci&oacute;n de dichos elementos. El contenido dispuesto en el Espacio Web no podr&aacute; ser reproducido ni en todo ni en parte, ni transmitido, ni registrado por ning&uacute;n sistema de recuperaci&oacute;n de informaci&oacute;n, en ninguna forma ni en ning&uacute;n medio, a menos que se cuente con la autorizaci&oacute;n previa, por escrito, de la citada Entidad.</p>
                <p>Asimismo, queda prohibido suprimir, eludir y/o manipular el &laquo;copyright&raquo; as&iacute; como los dispositivos t&eacute;cnicos de protecci&oacute;n, o cualesquiera mecanismos de informaci&oacute;n que pudieran contener los contenidos. El Usuario de este Espacio Web se compromete a respetar los derechos enunciados y a evitar cualquier actuaci&oacute;n que pudiera perjudicarlos, reserv&aacute;ndose en todo caso la empresa el ejercicio de cuantos medios o acciones legales le correspondan en defensa de sus leg&iacute;timos derechos de propiedad intelectual e industrial.</p>
                <p><strong>5. OBLIGACIONES Y RESPONSABILIDADES DEL USUARIO DEL ESPACIO WEB</strong></p>
                <p>El Usuario se compromete a:</p>
                <ol>
                    <li>
                        <p><strong>Uso Adecuado del Sitio Web</strong><p>El usuario se compromete a utilizar el sitio web y los servicios ofrecidos de manera responsable, adecuada y conforme a la ley, la moral, el orden p&uacute;blico y las presentes Condiciones de Uso. Queda prohibido el uso del sitio web con fines il&iacute;citos o que puedan perjudicar los derechos e intereses de&nbsp;<em>ShapeU!</em>, de sus propietarios, de otros usuarios, o de terceros.</p></p>
                    </li>
                    <li>
                        <p><strong>Veracidad de la Informaci&oacute;n Proporcionada</strong><p>El usuario garantiza que toda la informaci&oacute;n y datos personales que proporcione a trav&eacute;s del sitio web son veraces, exactos, completos y actualizados. El usuario ser&aacute; el &uacute;nico responsable de cualquier da&ntilde;o o perjuicio, directo o indirecto, que pudiera derivarse del incumplimiento de esta obligaci&oacute;n.</p></p>
                    </li>
                    <li>
                        <p><strong>Confidencialidad de las Credenciales de Acceso</strong><p>En caso de que se requiera la creaci&oacute;n de una cuenta o la utilizaci&oacute;n de credenciales de acceso, el usuario es responsable de mantener la confidencialidad de dichas credenciales (nombre de usuario y contrase&ntilde;a) y de todas las actividades que ocurran bajo su cuenta.&nbsp;<em>ShapeU!</em>&nbsp;no ser&aacute; responsable por cualquier p&eacute;rdida o da&ntilde;o que surja del incumplimiento de esta obligaci&oacute;n por parte del usuario.</p></p>
                    </li>
                    <li>
                        <p><strong>Prohibici&oacute;n de Conductas Abusivas y Fraudulentas</strong><p>El usuario se compromete a no realizar conductas abusivas, fraudulentas o que puedan da&ntilde;ar el funcionamiento del sitio web. Queda prohibido, entre otras cosas, el uso de bots, scripts automatizados, ingenier&iacute;a inversa, as&iacute; como la introducci&oacute;n de virus o cualquier otro c&oacute;digo malicioso.</p></p>
                    </li>
                    <li>
                        <p><strong>Respeto por la Propiedad Intelectual</strong><p>El usuario se obliga a respetar los derechos de propiedad intelectual e industrial de&nbsp;<em>ShapeU!</em>&nbsp;y de terceros. Esto incluye la prohibici&oacute;n de reproducir, distribuir, modificar o de cualquier manera explotar comercialmente los contenidos del sitio web sin el consentimiento expreso y por escrito de los titulares.</p></p>
                    </li>
                    <li>
                        <p><strong>Comunicaci&oacute;n y Contacto</strong><p>El usuario debe proporcionar datos de contacto precisos y mantener una comunicaci&oacute;n fluida con&nbsp;<em>ShapeU!</em>&nbsp;en caso de cualquier incidencia o solicitud relacionada con los servicios contratados. Cualquier fallo en la comunicaci&oacute;n debido a informaci&oacute;n incorrecta o desactualizada proporcionada por el usuario no ser&aacute; responsabilidad de&nbsp;<em>ShapeU!</em>.</p></p>
                    </li>
                    <li>
                        <p><strong>Cumplimiento de las Normas y Regulaciones Aplicables</strong><p>El usuario se compromete a cumplir con todas las normativas y regulaciones aplicables al uso de este sitio web, tanto a nivel local como internacional.</p></p>
                    </li>
                </ol>

                <p>No obstante lo establecido en el apartado anterior el Usuario deber&aacute; asimismo abstenerse de:</p>
                <ol>
                    <li>
                        <p><strong>Realizar Actividades Ilegales o No Autorizadas</strong><p>El Usuario se compromete a no utilizar el sitio web para llevar a cabo actividades ilegales, fraudulentas o que infrinjan la normativa aplicable, as&iacute; como a no utilizar el sitio web para fines no autorizados.</p></p>
                    </li>
                    <li>
                        <p><strong>Acceder o Interferir en el Sistema</strong><p>El Usuario deber&aacute; abstenerse de intentar acceder a &aacute;reas restringidas del sitio web, alterar, da&ntilde;ar o interrumpir el funcionamiento del mismo, o interferir en la seguridad del sitio web.</p></p>
                    </li>
                    <li>
                        <p><strong>Introducir Contenido Da&ntilde;ino o Inapropiado</strong><p>El Usuario no deber&aacute; introducir, publicar o transmitir contenido que sea difamatorio, obsceno, ofensivo, amenazante, o que pueda ser considerado como acoso o que infrinja los derechos de terceros.</p></p>
                    </li>
                    <li>
                        <p><strong>Utilizar el Sitio Web para Spam o Publicidad No Solicitada</strong><p>El Usuario se abstendr&aacute; de utilizar el sitio web para enviar spam, publicidad no solicitada o cualquier otro tipo de comunicaci&oacute;n comercial no autorizada.</p></p>
                    </li>
                    <li>
                        <p><strong>Violaci&oacute;n de Derechos de Propiedad Intelectual</strong><p>El Usuario no deber&aacute; reproducir, distribuir, modificar, mostrar p&uacute;blicamente o utilizar de cualquier otra forma los contenidos del sitio web sin la autorizaci&oacute;n previa y por escrito de&nbsp;<em>ShapeU!</em>&nbsp;o de los titulares de los derechos de propiedad intelectual.</p></p>
                    </li>
                    <li>
                        <p><strong>Compartir Informaci&oacute;n Confidencial</strong><p>El Usuario se compromete a no compartir ni divulgar informaci&oacute;n confidencial a la que pueda tener acceso a trav&eacute;s del sitio web sin el permiso expreso de los titulares de dicha informaci&oacute;n.</p></p>
                    </li>
                    <li>
                        <p><strong>Creaci&oacute;n de Falsas Identidades o Suplantaci&oacute;n de Identidad</strong><p>El Usuario no deber&aacute; crear identidades falsas, suplantar a otras personas o entidades, o presentar informaci&oacute;n enga&ntilde;osa en el sitio web.</p></p>
                    </li>
                    <li>
                        <p><strong>Facilitar Acceso No Autorizado a Terceros</strong><p>El Usuario no deber&aacute; permitir que terceros accedan a su cuenta o utilicen sus credenciales de acceso sin autorizaci&oacute;n.</p></p>
                    </li>
                </ol>

                <p><strong>6. RESPONSABILIDADES</strong></p>
                <p>No se garantiza el acceso continuado, ni la correcta visualizaci&oacute;n, descarga o utilidad de los elementos e informaciones contenidas en la web que puedan verse impedidos, dificultados o interrumpidos por factores o circunstancias que est&aacute;n fuera de su control. No se hace responsable de las decisiones que pudieran adoptarse como consecuencia del acceso a los contenidos o informaciones ofrecidas.</p>
                <p>Se podr&aacute; interrumpir el servicio, o resolver de modo inmediato la relaci&oacute;n con el Usuario, si se detecta que un uso de su Espacio Web, o de cualquiera de los servicios ofertados en el mismo, es contrario a las presentes Condiciones Generales de Uso. No nos hacemos responsables por da&ntilde;os, perjuicios, p&eacute;rdidas, reclamaciones o gastos derivados del uso del Espacio Web.</p>
                <p>&Uacute;nicamente ser&aacute; responsable de eliminar, lo antes posible, los contenidos que puedan generar tales perjuicios, siempre que as&iacute; se notifique. En especial no seremos responsables de los perjuicios que se pudieran derivar, entre otros, de:</p>
                <ol>
                    <li>
                        <p><strong>Interrupciones o Errores en el Servicio</strong><p>De interrupciones, fallos o errores en el funcionamiento del sitio web, que puedan afectar la disponibilidad o el acceso a los contenidos.</p></p>
                    </li>
                    <li>
                        <p><strong>Errores en la Informaci&oacute;n Proporcionada</strong><p>De inexactitudes, errores u omisiones en la informaci&oacute;n proporcionada en el sitio web, as&iacute; como de las consecuencias derivadas del uso de dicha informaci&oacute;n.</p></p>
                    </li>
                    <li>
                        <p><strong>Contenidos de Terceros</strong><p>Del contenido, servicios y enlaces a sitios web de terceros que puedan estar disponibles en el sitio web y que no est&eacute;n bajo nuestro control.</p></p>
                    </li>
                    <li>
                        <p><strong>Acceso No Autorizado</strong><p>De accesos no autorizados a sistemas de informaci&oacute;n o a datos personales de los usuarios, a pesar de las medidas de seguridad implementadas.</p></p>
                    </li>
                    <li>
                        <p><strong>Da&ntilde;os Derivados del Uso del Servicio</strong><p>De cualquier da&ntilde;o o perjuicio que pueda surgir del uso inadecuado o de la incapacidad para utilizar el sitio web o los servicios ofrecidos.</p></p>
                    </li>
                </ol>
                <p>La empresa excluye cualquier responsabilidad por los da&ntilde;os y perjuicios de toda naturaleza que pudieran deberse a la mala utilizaci&oacute;n de los servicios de libre disposici&oacute;n y uso por parte de los Usuarios de Espacio Web. Asimismo queda exonerado de cualquier responsabilidad por el contenido e informaciones que puedan ser recibidas como consecuencia de los formularios de recogida de datos, estando los mismos &uacute;nicamente para la prestaci&oacute;n de los servicios de consultas y dudas. Por otro lado, en caso de causar da&ntilde;os y perjuicios por un uso il&iacute;cito o incorrecto de dichos servicios, podr&aacute; ser el Usuario reclamado por los da&ntilde;os o perjuicios causados.</p>
                <p>Usted mantendr&aacute; a la empresa indemne frente a cualesquiera da&ntilde;os y perjuicios que se deriven de reclamaciones, acciones o demandas de terceros como consecuencia de su acceso o uso del Espacio Web. Asimismo, usted se obliga a indemnizar frente a cualesquiera da&ntilde;os y perjuicios, que se deriven del uso por su parte de &ldquo;robots&rdquo;, &ldquo;spiders&rdquo;, &ldquo;crawlers&rdquo; o herramientas similares empleadas con el fin de recabar o extraer datos o de cualquier otra actuaci&oacute;n por su parte que imponga una carga irrazonable sobre el funcionamiento del Espacio Web.</p>
                <p><strong>7. HIPERV&Iacute;NCULOS</strong></p>
                <p>El Usuario se obliga a no reproducir de ning&uacute;n modo, ni siquiera mediante un hiperenlace o hiperv&iacute;nculo, el Espacio Web, as&iacute; como ninguno de sus contenidos, salvo autorizaci&oacute;n expresa y por escrito del responsable del fichero.</p>
                <p>El Espacio Web puede incluir enlaces a otros espacios web, gestionados por terceros, con objeto de facilitar el acceso del Usuario a la informaci&oacute;n de empresas colaboradoras y/o patrocinadoras. Conforme con ello, la sociedad no se responsabiliza del contenido de dichos Espacios web, ni se sit&uacute;a en una posici&oacute;n de garante ni/o de parte ofertante de los servicios y/o informaci&oacute;n que se puedan ofrecer a terceros a trav&eacute;s de los enlaces de terceros.</p>
                <p><strong>8. PROTECCI&Oacute;N DE DATOS</strong></p>
                <p>Para utilizar algunos de los Servicios, el Usuario debe proporcionar previamente ciertos datos de car&aacute;cter personal. Los responsables de la web (ShapeU!) tratar&aacute; autom&aacute;ticamente estos datos y aplicar&aacute; las correspondientes medidas de seguridad, todo ello en cumplimiento del RGPD, LOPDGDD y LSSI. El Usuario puede acceder a la pol&iacute;tica seguida en el tratamiento de los datos personales, as&iacute; como el establecimiento de las finalidades previamente establecidas, en las condiciones definidas en la Pol&iacute;tica de Privacidad.</p>
                <p><strong>9.COOKIES</strong></p>
                <p>La empresa se reserva el derecho de utilizar la tecnolog&iacute;a &ldquo;cookie&rdquo; en el Espacio Web, a fin de reconocerlo como Usuario frecuente y personalizar el uso que realice del Espacio Web mediante la preselecci&oacute;n de su idioma, o contenidos m&aacute;s deseados o espec&iacute;ficos.Las cookies recopilan la direcci&oacute;n IP del usuario siendo Google el responsable del tratamiento de esta informaci&oacute;n.</p>
                <p>Las cookies son ficheros enviados a un navegador, por medio de un servidor Web, para registrar la navegaci&oacute;n del Usuario en el Espacio Web, cuando el Usuario permita su recepci&oacute;n. Si usted lo desea puede configurar su navegador para ser avisado en pantalla de la recepci&oacute;n de cookies y para impedir la instalaci&oacute;n de cookies en su disco duro. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta informaci&oacute;n.</p>
                <p>Gracias a las cookies, resulta posible que se pueda reconocer el navegador del ordenador utilizado por el Usuario con la finalidad de facilitar contenidos y ofrecer las preferencias de navegaci&oacute;n u publicitarias que el Usuario, a los perfiles demogr&aacute;ficos de los Usuarios as&iacute; como para medir las visitas y par&aacute;metros del tr&aacute;fico, controlar el progreso y n&uacute;mero de entradas.</p>
                <p><strong>10. DECLARACIONES Y GARANT&Iacute;AS</strong></p>
                <p>En general, los contenidos y servicios ofrecidos en el Espacio Web tienen car&aacute;cter meramente informativo. Por consiguiente, al ofrecerlos, no se otorga garant&iacute;a ni declaraci&oacute;n alguna en relaci&oacute;n con los contenidos y servicios ofrecidos en el Espacio web, incluyendo, a t&iacute;tulo enunciativo, garant&iacute;as de licitud, fiabilidad, utilidad, veracidad, exactitud, o comerciabilidad, salvo en la medida en que por ley no puedan excluirse tales declaraciones y garant&iacute;as.</p>
                <p><strong>11. FUERZA MAYOR</strong></p>
                <p>La empresa no ser&aacute; responsable en caso de imposibilidad de prestar servicio, si &eacute;sta se debe a interrupciones prolongadas del suministro el&eacute;ctrico, l&iacute;neas de telecomunicaciones, conflictos sociales, huelgas, rebeli&oacute;n, explosiones, inundaciones, actos y omisiones del Gobierno, y en general todos los supuestos de fuerza mayor o de caso fortuito.</p>
                <p><strong>12. RESOLUCI&Oacute;N DE CONTROVERSIAS. LEY APLICABLE Y JURISDICCI&Oacute;N</strong></p>
                <p>Las presentes Condiciones Generales de Uso, as&iacute; como el uso del Espacio Web, se regir&aacute;n por la legislaci&oacute;n espa&ntilde;ola. Para la resoluci&oacute;n de cualquier controversia las partes se someter&aacute;n a los Juzgados y Tribunales del domicilio social del Responsable del sitio web.</p>
                <p>En el supuesto de que cualquier estipulaci&oacute;n de las presentes Condiciones Generales de Uso resultara inexigible o nula en virtud de la legislaci&oacute;n aplicable o como consecuencia de una resoluci&oacute;n judicial o administrativa, dicha inexigibilidad o nulidad no har&aacute; que las presentes Condiciones Generales de Uso resulten inexigibles o nulas en su conjunto. En dichos casos, la empresa proceder&aacute; a la modificaci&oacute;n o sustituci&oacute;n de dicha estipulaci&oacute;n por otra que sea v&aacute;lida y exigible y que, en la medida de lo posible, consiga el objetivo y pretensi&oacute;n reflejados en la estipulaci&oacute;n original.</p>
            </section>

        </div>
    );
};

export default Legal;
