import React from 'react';
import {FaInstagram} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://www.instagram.com/shapeu_ne?igsh=MTE2dGs1MGQwNmE2Mw%3D%3D" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
      </div>
      <p>© 2024 ShapeU!. Todos los derechos reservados. <Link to={'/legal'}><u>Política de Privacidad y Términos</u></Link> | <Link to="/contacto">Contáctanos</Link></p>
    </footer>
  );
};

export default Footer;
