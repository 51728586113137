import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Box, Typography, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const PrivacyPolicyModal = () => {
  const location = useLocation();

  if (location.pathname === '/legal') {
    return null;
  }
  const disableGoogleAnalytics = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gid');
    Cookies.remove('_gat');
    window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`] = true;
    console.log("Google Analytics deshabilitado");
  };

  const handleReject = () => {
    Cookies.remove("user-consent");
    disableGoogleAnalytics();
    console.log('Cookies rechazadas');
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Aceptar"
      declineButtonText="Rechazar"
      cookieName="user-consent"
      expires={365}
      onAccept={() => console.log("Cookies aceptadas")}
      onDecline={handleReject}
      enableDeclineButton
      style={{
        background: '#f5ac7e',
        color: '#2B373B',
        textAlign: 'center',
        padding: '10px 20px',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 9999
      }}
      buttonStyle={{
        backgroundColor: '#38b6ff',
        color: 'white',
        fontSize: '14px',
        borderRadius: '4px',
        padding: '14px',
        border: 'none',
        margin: '5px 30px',
        display: 'inline-block',
        cursor: 'pointer'
      }}
      declineButtonStyle={{
        backgroundColor: 'transparent',
        color: 'white',
        border: '2px solid white',
        fontSize: '14px',
        borderRadius: '4px',
        padding: '12px',
        margin: '5px 0px 10px 70px',
        display: 'inline-block',
        cursor: 'pointer'
      }}
      declineButtonAriaLabel="Rechazar"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', paddingRight:'20px' }}>
        <Typography variant="body1" sx={{ color: '#2B373B', fontSize: '14px' }}>
          Utilizamos cookies y otras tecnologías para garantizar el correcto funcionamiento de nuestro sitio web y para analizar el tráfico. Lee nuestra{' '}
          <Link href="/legal" target="_blank" rel="noopener noreferrer" sx={{ color: '#38b6ff', textDecoration: 'none' }}>
            Política de Privacidad
          </Link>{' '}
          y nuestros{' '}
          <Link href="/legal" target="_blank" rel="noopener noreferrer" sx={{ color: '#38b6ff', textDecoration: 'none' }}>
            Términos de Servicio.
          </Link>
        </Typography>
      </Box>
    </CookieConsent>
  );
};

export default PrivacyPolicyModal;
