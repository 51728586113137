// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Button, Drawer, List, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import './Navbar.css';
import Logo from '../assets/shapeuplogo.svg';
import ModalLogin from './ModalLogin';
import { auth } from '../firebaseConfig';


const Navbar = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // Debe ser un booleano, no null
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('login');

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      if (drawerOpen) {
        toggleDrawer();
      }
      closeMenu();
    }).catch((error) => {
      console.error("Error al cerrar sesión. ");
    });
  };

  const handleModalOpen = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const CustomIconButton = styled(IconButton)({
    color: '#38b6ff'
  });

  const CustomAppBar = styled(AppBar)({
    backgroundColor: 'white',
    borderBlockEnd: '1px solid #ff914d',
    boxShadow: 'none',
  });

  const displayName = user?.displayName ? user.displayName.split(' ')[0] : '';

  return (
    <>
      <CustomAppBar position="fixed" className="navbar">
        <Toolbar>
          <CustomIconButton edge="start" aria-label="menu" onClick={toggleDrawer} className="menu-toggle">
            <MenuIcon />
          </CustomIconButton>
          <div className="logo-container">
            <Link to="/">
              <img src={Logo} alt="logo de la página web" className="logo" width={80} />
            </Link>
          </div>
          <div className="nav-menu">
            <Button component={Link} to="/" className="nav-item">Inicio</Button>
            <Button component={Link} to="/planes" className="nav-item">Planes</Button>
            <Button component={Link} to="/preguntas" className="nav-item">FAQ</Button>
            <Button component={Link} to="/conocenos" className="nav-item">Conócenos</Button>
            <Button component={Link} to="/contacto" className="nav-item">Contacto</Button>
          </div>
          <div className="nav-auth">
            {user ? (
              <>
                <div className="nav-auth">
                  <button className="profile-button" onClick={toggleMenu}>
                    👤 {displayName}
                  </button>
                  <div className={`dropdown-menu ${menuOpen ? 'show' : ''}`}>
                    <Link to="/perfil" onClick={closeMenu}>Ir a perfil</Link>
                    <button component={Link} to="/" onClick={handleLogout}>
                      <span className="logout-icon"><LogoutIcon style={{ marginRight: '2px', fontSize: '20px' }} /></span> Cerrar Sesión
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Button onClick={() => handleModalOpen('login')} className="nav-item login-btn">
                  Iniciar Sesión
                </Button>
                <Button onClick={() => handleModalOpen('register')} className="nav-item register-btn">
                  Registrarse
                </Button>
              </>
            )}
          </div>
        </Toolbar>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer} className="drawer">
          <div className="drawer-content">
            <IconButton onClick={toggleDrawer} className="drawer-close">
              <CloseIcon />
            </IconButton>
            <List>
              <ListItemButton component={Link} to="/" onClick={toggleDrawer} className="drawer-item">
                <ListItemIcon>
                  <HomeIcon />
                  <ListItemText primary="Inicio" color='black' />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton component={Link} to="/planes" onClick={toggleDrawer} className="drawer-item">
                <ListItemText primary="Planes" />
              </ListItemButton>
              <ListItemButton component={Link} to="/preguntas" onClick={toggleDrawer} className="drawer-item">
                <ListItemText primary="FAQ" />
              </ListItemButton>
              <ListItemButton component={Link} to="/conocenos" onClick={toggleDrawer} className="drawer-item">
                <ListItemText primary="Conócenos" />
              </ListItemButton>
              <ListItemButton component={Link} to="/contacto" onClick={toggleDrawer} className="drawer-item">
                <ListItemText primary="Contacto" />
              </ListItemButton>
              {user ? (
                <>
                  <ListItemButton component={Link} to="/perfil" onClick={toggleDrawer} className="drawer-item">
                    <ListItemText primary="👤 Mi Perfil" />
                  </ListItemButton>
                  <ListItemButton component={Link} to="/" onClick={handleLogout} className="drawer-item">
                    <LogoutIcon style={{ marginRight: '8px' }} />
                    <ListItemText primary="Cerrar Sesión" />
                  </ListItemButton>
                </>
              ) : (
                <>
                  <ListItemButton onClick={() => { toggleDrawer(); handleModalOpen('login'); }} className="drawer-item">
                    <ListItemText primary="Iniciar Sesión" />
                  </ListItemButton>
                  <ListItemButton onClick={() => { toggleDrawer(); handleModalOpen('register'); }} className="drawer-item">
                    <ListItemText primary="Registrarse" />
                  </ListItemButton>
                </>
              )}

            </List>
          </div>
        </Drawer>
      </CustomAppBar>

      {/* Modal for Login/Registration */}
      {modalOpen && (
        <ModalLogin
          type={modalType}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default Navbar;
