import React, { useState } from 'react';
import './Preguntas.css';

const Preguntas = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const preguntas = [
        {
            title: '¿Qué plan me recomiendas?',
            description: <p>Todos nuestros planes tienen en cuenta todos tus objetivos y necesidades, escojas el que escojas vas a mejorar tu salud. Nuestro plan base tiene todo lo que necesitas si estás comenzando a cuidarte, con el plan semanal y tu tabla adaptada a lo que necesitas puedes empezar a seguir tu camino hacia la salud. El plan estándar tiene un mayor seguimiento por parte del entrenador personal, ya que si tienes contacto con él; además, podrás hacer revisiones por videollamada con la nutricionista, por lo que el contacto es más directo en caso de que tengas alguna patología o tengas un objetivo más exigente. Por último tienes el plan premium, para personas que necesiten un mayor contacto o que requieran de mayor variedad en su plan mensual es el ideal. El contacto es mayor con los profesionales que en los otros planes, además, habría dos menús semanales al mes.</p>
        },
        {
            title: '¿Los planes son 100% adaptados a mi?',
            description: <p>Claro! Nos basamos en los cuestionarios iniciales, que son individuales, para poder crear un plan ajustado a las necesidades de cada uno y así no inventarnos nada. Además luego tienes las revisiones y ajustes donde nos contarás que te gusta y que no para que así nosotros terminemos  de personalizar tu plan.</p>
        },
        {
            title: '¿Si tengo preferencias en comidas o hábitos, te los puedo añadir en el cuestionario?',
            description: <p>Por supuesto, en el cuestionario queremos que especifiqueis todo en cuanto a hábitos, gustos, alergias o lesiones... para que todo esté adaptado para vosotros.</p>
        },
        {
            title: '¿Es posible hacer un entrenamiento combinando gimnasio y casa?',
            description: <p>¡Claro! Podemos adaptar el entrenamiento para que puedas hacer ejercicio tanto en casa como en el gimnasio. Durante la valoración inicial ajustaremos y modificaremos todo lo que haga falta para que tu camino sea lo más cómodo y efectivo posible.</p>
        },
        {
            title: '¿Hacéis dietas adaptadas a vegetarianos y veganos?',
            description: <p>Sí, no habría problema con este tipo de dietas, nos adaptamos a vosotr@s.</p>
        },
        {
            title: '¿Si tengo varias patologías, podría obtener un plan nutricional?',
            description: <p>Por supuesto, para esto te recomiendo nuestro plan estándar o premium, para poder tener mayor contacto con ambos profesionales y poder saber tu evolución de manera más directa. Además, con el plan premium podríamos ir modificando tu plan nutricional cada 15 días según mejora de tu sintomatología.</p>
        },
        {
            title: '¿Podría combinar clases dirigidas por mi cuenta (Spinning, Pilates, Yoga, Boxeo...) con vuestro entrenamiento?',
            description: <p>Sí, no habría problema siempre que se pueda realizar dentro del ámbito de la salud y consecución de metas propuestas, es decir, tenemos que saber el tipo de clase, la intensidad de estas, el volumen y frecuencia con la que las realizas para así poder ajustar el resto de variables y que exista un equilibrio y compatibilidad de estímulos que evite el sobreentrenamiento y redundancia de ejercicios además de ajustar la nutrición y los descansos.</p>
        }
    ];

    const toggleIndex = (index) => {
        if (window.innerWidth <= 938) {
            setActiveIndex(activeIndex === index ? null : index);
        }

    };
    return (
        <div className="preguntas-container">
            {preguntas.map((pregunta, index) => (
                <div key={index} className={`pregunta-bubble ${activeIndex === index ? 'active' : ''}`}>
                    <div className="pregunta-header" onClick={() => toggleIndex(index)}>
                        <h3 className="pregunta-title">{pregunta.title}</h3>
                        <span className="toggle-icon">
                            {activeIndex === index ? '−' : '+'}
                        </span>
                    </div>
                    <p className="pregunta-description">{pregunta.description}</p>
                </div>
            ))}
        </div>
    );
};

export default Preguntas;
