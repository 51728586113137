import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Cookies from 'js-cookie';

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const consent = Cookies.get('user-consent');  // O localStorage.getItem('user-consent')
    
    if (consent === 'accepted') {
      const pagePath = location.pathname + location.search;
      logEvent(analytics, 'page_view', {
        page_path: pagePath,
        page_location: window.location.href,
        page_title: document.title,
      });
    }
  }, [location]);

  return null; 
};

export default AnalyticsTracker;
