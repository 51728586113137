import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import emailjs from '@emailjs/browser';
import { getAuth } from 'firebase/auth';
import './Contacto.css';

const Contacto = () => {
    const form = useRef(); //useRef para referenciar el formulario
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        contactDate: '',
        contactTime: '',
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            setFormData((prevData) => ({
                ...prevData,
                name: user.displayName || '',
                email: user.email || '',
                phone: user.phoneNumber || '',
            }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.name || !formData.email || !formData.message) {
            setError('Todos los campos son obligatorios.');
            return;
        }
        setError('');

        // Envío del correo al administrador
        emailjs
            .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
            .then(() => {
                setSuccessMessage('Formulario enviado con éxito. Ya nos pondremos en contacto contigo.');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    contactDate: '',
                    contactTime: '',
                });
            }, (error) => {
                setError('Error al enviar el formulario. Inténtalo de nuevo.');
                console.error('Error al enviar el correo al administrador:', error);
            });

        // Envío del correo de confirmación al usuario
        emailjs
            .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID_USER, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
            .then(() => {
                setSuccessMessage('Correo de confirmación enviado al usuario.');
            }, (error) => {
                console.error('Error al enviar el correo de confirmación al usuario:', error);
            });
    };

    return (
        <form ref={form} onSubmit={handleSubmit} className="contact-form">
            <TextField
                label="Nombre Completo"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
            />
            <TextField
                label="Correo Electrónico"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
            />
            <TextField
                label="Número de Teléfono"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Mensaje"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                multiline
                rows={4}
            />
            <TextField
                label="Fecha Preferida para Contacto"
                type="date"
                name="contactDate"
                value={formData.contactDate}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="Horario Preferido para Contacto"
                type="time"
                name="contactTime"
                value={formData.contactTime}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
            />
            {error && <Typography color="error" style={{ marginTop: '10px', color: 'red' }}>{error}</Typography>}
            {successMessage && <Typography color="primary" style={{ marginTop: '10px', color: 'green' }}>{successMessage}</Typography>}
            <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '20px' }}>
                Enviar
            </Button>
        </form>
    );
};

export default Contacto;
