import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Preguntas from './pages/Preguntas';
import Contacto from './pages/Contacto';
import Perfil from './pages/Perfil';
import { auth } from './firebaseConfig';
import Planes from './pages/Planes';
import Conocenos from './pages/Conocenos';
import Footer from './components/Footer';
import Legal from './pages/Legal';
import PrivacyPolicyModal from './components/ModalPrivacidad';
import AnalyticsTracker from './components/GoogleAnalytics';

function App() {
  //DEEFINIMOS QUE EL USUARIO NO ESTA LOGADO
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // Usuario autenticado, actualizar el estado
      } else {
        setUser(null); // No hay usuario autenticado
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <AnalyticsTracker />
      <div className='App'>
        <Navbar user={user} />
        <div className='content'>
          <Routes>
            <Route exact path='/' Component={Home} />
            <Route path='/planes' Component={Planes} />
            <Route path='/conocenos' Component={Conocenos} />
            <Route path='/preguntas' Component={Preguntas} />
            <Route path='/contacto' Component={Contacto} />
            <Route path='/perfil' Component={Perfil} />
            <Route path='/legal' Component={Legal} />
          </Routes>
          <PrivacyPolicyModal />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
