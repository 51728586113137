import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, CircularProgress, Avatar, Box } from '@mui/material';
import {updateProfile, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import logo from '../assets/shapeuplogo.svg';

const Perfil = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setOriginalEmail(currentUser.email);
        setFullName(currentUser.displayName || '');
        setEmail(currentUser.email || '');
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleUpdateProfile = async () => {
    if (!user) return;

    try {
      
      if (email && !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        setEmailError(true);
        return;
      } else {
        setEmailError(false);
      }

      if (fullName.trim() !== '') {
        await updateProfile(user, { displayName: fullName });
      }

      if (email && email !== originalEmail) {
        await user.updateEmail(email);
        setOriginalEmail(email);
      }

      setMessage('Perfil actualizado con éxito.');
    } catch (error) {
      setMessage('Error al actualizar el perfil.');
    }
  };

  const isButtonDisabled =
    (fullName.trim() === user?.displayName?.trim()) &&
    (email.trim() === originalEmail.trim());

  if (loading) {
    return <CircularProgress />;
  }

  if (!user) {
    return <Typography>No se encontró al usuario.</Typography>;
  }

  return (
    <div style={{ margin: '10px' }}>
      <Typography variant="h4" gutterBottom>Perfil de Usuario</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar
          alt="Usuario"
          src={logo}
          sx={{ width: 80, height: 80 }}
        />
      </Box>
      <TextField
        label="Nombre Completo"
        variant="outlined"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Correo Electrónico"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        type='email'
        error={emailError}
        helperText={emailError ? "Ingrese un correo electrónico válido." : ""}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateProfile}
        style={{ marginTop: '16px' }}
        disabled={isButtonDisabled}
      >
        Actualizar Perfil
      </Button>
      {message && <Typography variant="body1" color="textSecondary" style={{ marginTop: '16px', color: 'red' }}>{message}</Typography>}
    </div>
  );
};

export default Perfil;
